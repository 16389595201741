<template>
	<ListServices
		:status="status"
		:textCalendar="textCalendar"
		v-model="dateCalendar"
	/>
</template>

<script>
import { mapMutations } from "vuex";
const ListServices = () =>
	import(
		/* webpackChunkName: "ListServices" */ "@/components/listServices.vue"
	);

export default {
	name: "Services",
	components: { ListServices },
	data() {
		return {
			status: ["active"],
			textCalendar: this.$t("global.components.services.txtCalendar"),
		};
	},
	methods: {
		...mapMutations("services", {
			setDateService: "setDateService",
		}),
	},
	computed: {
		dateCalendar: {
			get() {
				return this.$store.state.services.calendar.services;
			},
			set(val) {
				this.setDateService(val);
			},
		},
	},
};
</script>
